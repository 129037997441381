<template>
<div>
<nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="page-header">
        <h3 class="page-title">Department Details</h3>
      </div>
      <div class="row">        
        <div class="card-body">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group row">
                            <div class="col-sm-12" >
                                <label class="col-form-label">Department Name</label>
                                <input type="text" class="form-control" v-model="$v.typeform.department_name.$model" :class="{ 'is-invalid':  $v.typeform.department_name.$error  }" name="department_name" placeholder="Department Name"/>
                            </div>
                            <div v-if="$v.typeform.department_name.$error" class="invalid-feedback">
                                <span v-if="!$v.typeform.department_name.required">Please enter department name</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group row">
                            <div class="col-sm-12" >
                                <label class="col-form-label">Description(Optional)</label>
                                <textarea rows="2" type="text" class="form-control" v-model="$v.typeform.department_desc.$model" name="department_desc" placeholder="Department Desc"/>
                            </div>
                            <div class="mt-1">
                              <small class="text-muted">This description will display on landing page below doctor name</small>
                            </div>
                        </div>
                    </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label class="col-form-label">Status</label>

                            <multiselect v-model="$v.typeform.department_status.$model" :options="status"
                                         :multiple="false" :close-on-select="true" :clear-on-select="false"
                                         :preserve-search="true" placeholder="Status"
                                         :preselect-first="true"></multiselect>


                                <div v-if="$v.typeform.department_status.$error" class="invalid-feedback">
                                    <span v-if="!$v.typeform.department_status.required">Please select status</span>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                <br/><br/>
                <div class="col-md-12">
                    <b-button v-if="is_btn_spinner" variant="primary" disabled>
                        <b-spinner small type="grow"></b-spinner>Loading...
                    </b-button>
                    <button v-if="!is_btn_spinner" type="button" @click="submitForm()" class="btn btn-gradient-primary me-2">Submit</button>
                    <!-- <button class="btn btn-light">Cancel</button> -->
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
    <Footer/>
    </div>
  </div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import string from "../../constant/strings.js"

export default {
    name:'departmentdetails',
    title: string.PAGE_TITLE_DEPARTMENT_DETAILS,
    components: {
      NavBar,
Footer,
      SideBar,
      Multiselect,
    },
    data() {
    return {
        status :['Active','Inactive'],
      moment:moment,
      typeform:{
        department_name:'',
        department_status:'',
        department_desc:'',
      },
      is_btn_spinner:false,
    }
    },
    validations: {
    typeform: {
      department_name:{required},
      department_status:{required},
      department_desc:{},
    },
  },

  mounted(){
    if (this.$route.params.dpid != ''){
        this.getDepartmentDetails();
    }
  },

  methods:{
    ...mapActions("hospital",["getDepartmentDetailData", "editDepartmentDetailsData", "addDepartmentData"]),

    getDepartmentDetails(){
      var bodyFormData = new FormData();      
      bodyFormData.append('department_id', parseInt(this.$route.params.dpid));
      this.getDepartmentDetailData(bodyFormData).then((response) => {
        if (response.response_code == 200) {        
            this.typeform = {
                department_name :response.data.department_name,
                department_desc :response.data.department_desc,
                department_status : response.data.department_status,
            }
        } else{
            this.$toasted.error(response.message, {duration: 2000,});
        }
      });
    },

    submitForm(){
        this.$v.$touch();
        if (!this.$v.typeform.$invalid) {
            this.is_btn_spinner = true;
            var bodyFormData = new FormData();
            bodyFormData.append('department_name', this.typeform.department_name);
            bodyFormData.append('department_status', this.typeform.department_status);
            bodyFormData.append('department_desc', this.typeform.department_desc);

            if (this.$route.params.dpid != ''){
                bodyFormData.append('department_id', this.$route.params.dpid);
                this.editDepartmentDetailsData(bodyFormData).then((response) => {
                  this.is_btn_spinner = false;
                  if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    this.$router.push({ name: 'departmentlist' });
                  }else{
                    this.$toasted.error(response.message, {duration: 2000,});
                  }
                });
            } else {
                this.addDepartmentData(bodyFormData).then((response) => {
                  this.is_btn_spinner = false;
                  if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    this.$router.push({ name: 'departmentlist' });
                  }else{
                    this.$toasted.error(response.message, {duration: 2000,});
                  }
                });
            }
          }
        },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
